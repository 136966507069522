import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import PromptContainer from "./PromptContainer";
import { useState } from "react";
import courseIcon from "../../../assets/img/courses.jpg";

const AddOtherCourse = ({ show, handleClose }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [sloading, setSLoading] = useState(false);

  const searchItem = async (text) => {
    setSLoading(true);
    try {
      const response = await axios.post("CourseSearch", { text: text });
      const resp = response.data.data;
      setSearchResults(resp);
    } catch (error) {
      toast.error(error);
    } finally {
      setSLoading(false); // Whether request succeeds or fails, set loading to false
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeader closeButton>
        <h2 className="text-blue"> Add Other Courses</h2>
      </ModalHeader>
      <ModalBody>
        <div className="container">
          <input
            type="search"
            autoFocus
            className="search-bar-study ms-auto p-3"
            placeholder="Search here"
            onChange={(e) => searchItem(e.target.value)}
          />
          <div className="row row-cols-1 p-2 mt-2">
            {sloading ? (
              <div className="col text-center">
                <span className="text-blue spinner-border spinner-border-sm"></span>
              </div>
            ) : (
              searchResults.map((course) => (
                <div className="col" key={course.id}>
                  <div className="all-courses-card mb-5">
                    <div className="all-courses-img">
                      <img src={courseIcon} className="" alt="" />
                    </div>
                    <div className="all-courses-body p-3 w-100">
                      <div className="d-block">
                        <h4 className="roboto-14-700">{course.course_name}</h4>
                        <div className="">
                          <span className="roboto-12-400 ms-auto">
                            {course.course_level} Level
                          </span>
                        </div>
                      </div>
                      <PromptContainer courseId={course.id} />
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
          <button className="blue-filled-btn" onClick={handleClose}>
            Close
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddOtherCourse;
