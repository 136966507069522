import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "../api/axios";
import { toast } from "react-toastify";

const EditUserProfile = ({ show, handleClose }) => {
  const [NickName, setNickname] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);
  const [saving, setSaving] = useState(false); // State for tracking saving process

  useEffect(() => {
    fetchUserData();
  }, []);
  const handleNicknameChange = (e) => {
    setNickname(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    setProfilePicture(file);
  };

  const fetchUserData = () => {
    const nickname = sessionStorage.getItem("nickname");
    const userPhone = sessionStorage.getItem("userPhone");
    // const userPic = sessionStorage.getItem("userPic");

    // // Set the initial profile picture if available
    // if (userPic) {
    //   setProfilePicture(userPic);
    // }

    setNickname(nickname);
    setPhoneNumber(userPhone);
  };

  const handleSaveProfile = () => {
    const userID = sessionStorage.getItem("userID");
    const ProfilePic = profilePicture;
    const obj = { NickName, ProfilePic, PhoneNumber };
    setSaving(true);
    axios
      .post(`UpdateProfile/${userID}`, obj, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        let resp = response.data.data;
        if (resp.status === "success") {
          console.log(resp.message);
          toast.success("Profile Updated", {
            theme: "colored",
            autoClose: 3000,
          });
          setSaving(false);
          handleClose();
        } else {
          toast.error(resp.message, {
            theme: "colored",
            autoClose: 3000,
          });
          setSaving(false);
        }
      })
      .catch((err) => {
        toast.error("Edit failed :" + err.message, {
          theme: "colored",
          autoClose: 3000,
        });
        setSaving(false);
      });
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h2 className="viga-24-400">Edit Profile</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="edit-profile">
          <div className="profile-picture mb-3">
            <img
              src={
                profilePicture
                  ? URL.createObjectURL(profilePicture)
                  : "path/to/default/profile-picture.jpg"
              }
              alt="Profile"
              className="profile-pic-lg"
            />
            <input
              type="file"
              accept="image/*"
              onChange={handleProfilePictureChange}
              className="form-control mt-3"
            />
          </div>
          <div className="form-group mb-3">
            <label>Nickname:</label>
            <input
              type="text"
              value={NickName}
              onChange={handleNicknameChange}
              className="form-control"
            />
          </div>
          <div className="form-group mb-3">
            <label>Phone Number:</label>
            <input
              type="text"
              value={PhoneNumber}
              onChange={handlePhoneNumberChange}
              className="form-control"
            />
          </div>
          <button onClick={handleSaveProfile} className="blue-filled-btn">
            {saving ? (
              <span className="spinner-border spinner-border-sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </span>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditUserProfile;
