import { useEffect, useState } from "react";
import pqIcon from "../../../../assets/img/Pastquestions solution.jpg";
import { toast } from "react-toastify";
import axios from "../../../../api/axios";
import { Link } from "react-router-dom";

const PqResources = ({ courseId }) => {
  const [Pastquestions, setPastquestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ratings, setRatings] = useState({});
  const userId = sessionStorage.getItem("userID");
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(`pastquestions/${courseId}`);
        const resp = response.data.data;
        console.log(resp.message);
        //
        // const getView = await axios.get(`Views/pastquestion/13`)
        // const ratingsview = await axios.get(`rate/pastquestion/{}`)

        if (
          resp.message === "" ||
          resp.message === "No record Found" ||
          resp.message === "no record found"
        ) {
          setPastquestions([]);
        } else {
          setPastquestions(resp.message);
        }
        setLoading(false);
      } catch (error) {
        toast.error(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [courseId]);

  // Function to handle rating changes
  const handleRatingChange = (id, rating) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [id]: rating,
    }));

    // Send rating to endpoint
    sendRatingToEndpoint(id, rating);
  };
  // Function to send rating to an endpoint
  const sendRatingToEndpoint = async (id, rating) => {
    const obj = {
      user_id: userId,
      resource_type: "pastquestion",
      resource_id: parseInt(id),
      rating,
    };
    try {
      const response = await axios.post(`reviews/${userId}`, obj);
      const resp = response.data;
      if (resp.message === "Review added successfully") {
        toast.success("Review added", {
          theme: "colored",
          autoClose: "2000",
        });
      } else {
        toast.warning("Error !", {
          theme: "colored",
          autoClose: "2000",
        });
      }
    } catch (error) {
      // Handle error
      console.error("Error sending rating:", error);
    }
  };

  return (
    <div className="row row-cols-1 row-cols-md-3 row-cols-lg-4 g-4">
      {loading ? (
        <div className="text-center">
          <span
            className="spinner-border spinner-border-sm text-blue"
            role="status"
          ></span>
        </div>
      ) : (
        <>
          {Pastquestions.length === 0 ? (
            <div className="text-center">
              <p>No resource available</p>
            </div>
          ) : (
            <>
              {Pastquestions.map((textbook) => (
                <div className="col" key={textbook.id}>
                  <div className="card resource-card border-0">
                    <Link
                      to={`/courses/coursepage/resourcepage/document/${encodeURIComponent(
                        textbook.sourceurl
                      )}`}
                      className="text-decoration-none text-dark"
                      onClick={() => {
                        sessionStorage.setItem(
                          "activeResourceID",
                          textbook.id
                        );
                        sessionStorage.setItem(
                          "activeResourceType",
                          "pastquestion"
                        );
                      }}
                    >
                      <img src={pqIcon} className="card-img-top" alt="" />
                    </Link>
                    <div className="rating-overlay">
                      <span className="text-white">
                        {" "}
                        Rated {textbook.rating}
                      </span>
                      <span className="text-gold fs-5">★</span>
                    </div>
                    <div className="p-2">
                      <div className="d-flex ">
                        <h6 className="card-title">{textbook.Name}</h6>
                        <div className="ms-auto">
                          <i className="bi bi-eye pe-2"></i>
                          {textbook.views}
                        </div>
                      </div>
                      <div className="d-flex footnote-grey">
                        <div>
                          <h6 className="viga-14-400">
                            Author:{" "}
                            {textbook.author == null
                              ? "ETC Vibes"
                              : textbook.author}
                          </h6>
                          <div className="viga-14-400">
                            From: {textbook.institution}
                          </div>
                        </div>
                        <div className="ms-auto ratings">
                          {/* 5-star rating input */}
                          {[1, 2, 3, 4, 5].map((star) => (
                            <span
                              key={star}
                              onClick={() =>
                                handleRatingChange(textbook.id, star)
                              }
                              onMouseEnter={() =>
                                setRatings({ ...ratings, hoverRating: star })
                              }
                              onMouseLeave={() =>
                                setRatings({ ...ratings, hoverRating: null })
                              }
                              style={{
                                cursor: "pointer",
                                color:
                                  star <=
                                  (ratings.hoverRating ||
                                    ratings[textbook.id] ||
                                    0)
                                    ? "gold"
                                    : "gray",
                                transition: "color 0.2s ease-in-out", // Animation duration
                              }}
                            >
                              ★
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <MyPdfViewer url={textbook.sourceurl} /> */}
                </div>
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PqResources;
