import React, { useState } from "react";
import DashboardSidebar from "../pages/UserDashboard/DashboardSidebar";
import DashboardHeader from "../pages/UserDashboard/DashboardHeader";
import LogoutModal from "./LogoutModal";
import MobileNav from "./MobileNav";
import { ToastContainer, Zoom, toast } from "react-toastify";
import axios from "../api/axios";

const Profile = () => {
  const userID = sessionStorage.getItem("userID");
  const initialProfile = {
    username: sessionStorage.getItem("username"),
    nickname: sessionStorage.getItem("nickname"),
    useremail: sessionStorage.getItem("userEmail"),
    userPhone: sessionStorage.getItem("userPhone"),
    userPic: sessionStorage.getItem("userPic"),
  };

  const [profile, setProfile] = useState(initialProfile);
  const [editMode, setEditMode] = useState(false);
  const [saving, setSaving] = useState(false);
  const [profilePicFile, setProfilePicFile] = useState(null);

  const handleToggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleSaveProfile = () => {
    const formData = new FormData();
    formData.append("NickName", profile.nickname);
    formData.append("PhoneNumber", profile.userPhone);

    if (profilePicFile) {
      formData.append("ProfilePic", profilePicFile);
    } else {
      formData.append("ProfilePic", profile.userPic);
    }

    setSaving(true);

    axios
      .post(`UpdateProfile/${userID}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        let resp = response.data.data;
        if (resp.status === "success") {
          console.log(resp.message);
          toast.success("Profile Updated", {
            theme: "colored",
            autoClose: 2000,
          });
          setProfile({
            ...profile,
            userPic: resp.UserRecord.ProfilePicName,
            nickname: resp.UserRecord.NickName,
            userPhone: resp.UserRecord.PhoneNumber,
          });
          setProfilePicFile(null);
          setSaving(false);
          setEditMode(false);
        } else {
          toast.error(resp.message, {
            theme: "colored",
            autoClose: 3000,
          });
          setSaving(false);
        }
      })
      .catch((err) => {
        toast.error("Edit failed: " + err.message, {
          theme: "colored",
          autoClose: 3000,
        });
        setSaving(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleProfilePicChange = (e) => {
    setProfilePicFile(e.target.files[0]);
  };
  return (
    <section className="container-fluid p-0">
      <DashboardSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3 userDashboard">
        <DashboardHeader />
        <div className="p-4">
          <h2 className="viga-24-400">My Profile</h2>
          <div className=" mb-3">
            <img
              src={profile.userPic}
              className="profile-pic-lg mb-2"
              alt="Profile pic"
            />
            {editMode ? (
              <input
                type="file"
                accept="image/*"
                onChange={handleProfilePicChange}
                className="form-control w-50"
              />
            ) : (
              ""
            )}
          </div>
          <div className="profile-info">
            <div className="d-block d-md-block">
              {/* full name */}
              <div className="d-flex mb-2 me-2 me-md-5">
                <i className="bi bi-person-circle profile-grey me-2"></i>
                <div className="d-block ">
                  <p className="profile-grey m-0">Full Name</p>
                  <span className="profile-name lato-20-600">
                    {profile.username}
                  </span>
                </div>
              </div>
              {/* email */}
              <div className="d-flex mb-2">
                <i className="bi bi-envelope profile-grey me-2"></i>
                <div className="d-block">
                  <p className="profile-grey m-0">Email Address</p>
                  <span className="profile-name lato-20-600">
                    {profile.useremail}
                  </span>
                </div>
              </div>
            </div>
            <div className="d-block d-md-block">
              {/* Phone Number */}
              <div className="d-flex mb-2 me-2 me-md-5">
                <i className="bi bi-telephone profile-grey me-2"></i>
                <div className="d-block">
                  <p className="profile-grey m-0">Phone Number</p>
                  {editMode ? (
                    <input
                      type="text"
                      name="userPhone"
                      className="form-control"
                      value={profile.userPhone}
                      onChange={handleChange}
                    />
                  ) : (
                    <span className="profile-name lato-20-600">
                      {profile.userPhone}
                    </span>
                  )}
                </div>
              </div>

              {/* Nickname */}
              <div className="d-flex mb-3">
                <i className="bi bi-book profile-grey me-2"></i>
                <div className="d-block">
                  <p className="profile-grey m-0">Nickname</p>
                  {editMode ? (
                    <input
                      type="text"
                      name="nickname"
                      className="form-control"
                      value={profile.nickname}
                      onChange={handleChange}
                    />
                  ) : (
                    <span className="profile-name lato-20-600">
                      {profile.nickname}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex">
              {editMode ? (
                <>
                  <button
                    className="blue-filled-btn ms-auto"
                    onClick={handleSaveProfile}
                    disabled={saving}
                  >
                    {saving ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                      ></span>
                    ) : (
                      "Save"
                    )}
                  </button>
                  <button
                    className="grey-btn ms-2"
                    onClick={handleToggleEditMode}
                    disabled={saving}
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <button
                  className="blue-filled-btn ms-auto"
                  onClick={handleToggleEditMode}
                >
                  Edit Profile
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <MobileNav />
      <ToastContainer transition={Zoom}></ToastContainer>
    </section>
  );
};

export default Profile;
